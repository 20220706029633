import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()




//DONE: 1.⁠ ⁠Design (Rename to Product Designs)
//DONE: 2.⁠ ⁠⁠While Creating a New Product design, First Select Category then select Piece count based on the selection generate Article Prefix
//TODO: 3.⁠ Don't do because Same Spec Form may reuse for multiple categories -⁠ ⁠Piece Spec Name (List only specs based on the Category eg. Category Kurtha then piece spec name list Kurtha top, Kurtha bottom, etc
//TODO: 4.⁠ Don't do because Same Spec Form may reuse for multiple categories - ⁠⁠In details page Auto rename Piece based on Spec form name
//DONE: 5.⁠ ⁠⁠Add extra field text name … example size 16 (XS)
//TODO: 6.⁠ ⁠⁠Update MRP Calculation based on MU
